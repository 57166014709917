import React from "react"
import { Link } from "react-router-dom"
import CountUp from "react-countup"

import projects_done from "../Assets/Icon/home3/projects_done.png"
import domestic_clients from "../Assets/Icon/home3/domestic_clients.png"
import owned_vehicles from "../Assets/Icon/home3/owned_vehicles.png"
import people_in_team from "../Assets/Icon/home3/people_in_team.png"

import danone from "../Assets/Customers/danone.png"
import cleo from "../Assets/Customers/cleo.png"
import sampoerna from "../Assets/Customers/sampoerna.png"
import matahari from "../Assets/Customers/matahari.png"
import wings from "../Assets/Customers/wings.png"
import indocement from "../Assets/Customers/indocement.png"
import kompas_gramedia from "../Assets/Customers/kompas_gramedia.png"
import hero from "../Assets/Customers/hero.png"
import indofood from "../Assets/Customers/indofood.png"

export default function AboutUs() {
    return (
        <div style={{ width: "100vw" }}>
            <div className="about-us1">
                <h1>About Us</h1>
                <div>
                    <div>
                        <Link to="/" style={{ color: "inherit" }}>Home</Link>
                    </div>
                    <div>/</div>
                    <div>About Us</div>
                </div>
            </div>
            <div className="about-us2"></div>
            <div className="home3">
                <div>
                    <img height="50" src={projects_done} alt="projects_done" />
                    <br /><br />
                    <h3><CountUp start={0} end={1160} duration={5} />+</h3>
                    <div>Projects Done</div>
                </div>
                <div>
                    <img height="50" src={domestic_clients} alt="domestic_clients" />
                    <br /><br />
                    <h3><CountUp start={0} end={120} duration={5} />+</h3>
                    <div>Domestic Clients</div>
                </div>
                <div>
                    <img height="50" src={owned_vehicles} alt="owned_vehicles" />
                    <br /><br />
                    <h3><CountUp start={0} end={25} duration={5} />+</h3>
                    <div>Owned Vehicles</div>
                </div>
                <div>
                    <img height="50" src={people_in_team} alt="people_in_team" />
                    <br /><br />
                    <h3><CountUp start={0} end={25} duration={5} />+</h3>
                    <div>People in Team</div>
                </div>
            </div>
            <div className="home9">
                <h1>Our Customers</h1>
                <br />
                <div>
                    <img height="40" src={danone} alt="danone" />
                    <img height="70" src={cleo} alt="cleo" />
                    <img height="110" src={sampoerna} alt="sampoerna" />
                    <img height="30" src={matahari} alt="matahari" />
                    <img height="50" src={wings} alt="wings" />
                    <img height="80" src={indocement} alt="indocement" />
                    <img height="90" src={kompas_gramedia} alt="kompas_gramedia" />
                    <img height="40" src={hero} alt="hero" />
                    <img height="30" src={indofood} alt="indofood" />
                </div>
            </div>
        </div>
    )
}