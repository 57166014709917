import React from "react"
import { Link } from "react-router-dom"
import emailjs from "emailjs-com"

import danone from "../Assets/Customers/danone.png"
import cleo from "../Assets/Customers/cleo.png"
import sampoerna from "../Assets/Customers/sampoerna.png"
import matahari from "../Assets/Customers/matahari.png"
import wings from "../Assets/Customers/wings.png"
import indocement from "../Assets/Customers/indocement.png"
import kompas_gramedia from "../Assets/Customers/kompas_gramedia.png"
import hero from "../Assets/Customers/hero.png"
import indofood from "../Assets/Customers/indofood.png"

export default function ContactsMobile() {
    const form = React.useRef()
    const submitForm = (e) => {
        e.preventDefault()
        emailjs.sendForm("service_ei1vxtv", "template_2qxf5vd", form.current, "QoiiFNSvmYQiJFmkE")
            .then((res) => {
                console.log(res)
                document.getElementById("theForm").reset()
            }, (err) => console.log(err))
    }

    return (
        <div>
            <div style={{ height: 185, color: "white" }} className="services1 d-flex justify-content-evenly align-items-center">
                <h1 style={{ fontSize: 35 }}>Contacts</h1>
                <div className="d-flex justify-content-between" style={{ background: "", width: "30%", fontSize: 10 }}>
                    <div>
                        <Link to="/" style={{ color: "inherit" }}>Home</Link>
                    </div>
                    <div>/</div>
                    <div style={{ background: "", color: "#DCC45F", width: "45%" }}>Contacts</div>
                </div>
            </div>
            <div style={{ height: 319, background: "black", color: "white", padding: 35 }}>
                <h1 style={{ fontSize: 19 }}>Contact Us</h1>
                <div className="contacts2input">
                    <div style={{ fontSize: 11, margin: "15px 0px 30px", padding: "0px 120px 0px 0px", color: "lightgrey" }}>For inquiries and further information about AVI Cargo Services or how we can support your business, please contact us directly through the categories below</div>
                    <form ref={form} onSubmit={submitForm} id="theForm" className="d-flex">
                        <div className="d-flex flex-column">
                            <input required placeholder="Name*" name="from_name" />
                            <input required placeholder="Email*" name="from_email" style={{ marginTop: 10 }} type="email" />
                        </div>
                        <div className="d-flex flex-column" style={{ marginLeft: 20 }}>
                            <textarea required placeholder="Message*" name="message" />
                            <button style={{ color: "black", fontSize: 8, background: "#DBBC41", border: "1px solid transparent", borderRadius: "30px", width: "140px", height: "25px", marginTop: 10 }} type="submit">
                                <span>SUBMIT</span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="home9" style={{ height: 101, padding: "20px 25px" }}>
                <div className="Gotham-Med">Our Customers</div>
                <div className="d-flex justify-content-between align-items-center" style={{ marginTop: 5 }}>
                    <img height="10" src={danone} alt="danone" />
                    <img height="20" src={cleo} alt="cleo" />
                    <img height="30" src={sampoerna} alt="sampoerna" />
                    <img height="8" src={matahari} alt="matahari" />
                    <img height="15" src={wings} alt="wings" />
                    <img height="20" src={indocement} alt="indocement" />
                    <img height="25" src={kompas_gramedia} alt="kompas_gramedia" />
                    <img height="10" src={hero} alt="hero" />
                    <img height="10" src={indofood} alt="indofood" />
                </div>
            </div>
        </div>
    )
}