import React from "react"

import logo from "../Assets/logo.png"
import logo2 from "../Assets/logo2.png"

export default function Footer() {
    return (
        <>
            <div className="footerMain">
                <div>
                    <div>
                        <img height="55" src={logo} alt="logo" style={{ marginLeft: -10 }} />
                        <img height="55" src={logo2} alt="logo2" style={{ marginLeft: -10, display: "none" }} />
                        <br /><br />
                        <div>Everyday is a new day for us and we work really hard</div>
                        <div>to satisfy our customers everywhere.</div>
                    </div>
                    <div>
                        <div>
                            <div><i className="fas fa-map-marker-alt"></i></div>
                            <div>
                                <a style={{ color: "black" }} href="https://goo.gl/maps/LTYRdSj4KoNMA9Vd6" target="_blank" rel="noreferrer">
                                    <div>Gading Orchard Summarecon</div>
                                    <div>Blok GOS No. B08, Jl. Terusan Kelapa</div>
                                    <div>Hybrida Summarecon, Kelapa Gading,</div>
                                    <div>Jakarta Utara, 14140</div>
                                </a>
                            </div>
                        </div>
                        <div>
                            <div><i className="fas fa-mobile-alt"></i></div>
                            <div>
                                <div style={{ width: "54%", display: "flex", justifyContent: "space-between" }}>
                                    <div>Tel:</div>
                                    <div>(021)290-766-76</div>
                                </div>
                                <div style={{ width: "54%", display: "flex", justifyContent: "space-between" }}>
                                    <div>Fax:</div>
                                    <div>(021)290-766-75</div>
                                </div>
                                <div style={{ width: "54%", display: "flex", justifyContent: "space-between" }}>
                                    <div>WA:</div>
                                    <div>0813-8853-5357</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div><i className="far fa-envelope"></i></div>
                            <div><a href="mailto:avicargoservice@gmail.com">avicargoservice@gmail.com</a></div>
                        </div>
                    </div>
                </div>
                <hr/>
                <div>Copyright © 2021 by Avi Cargo Services. All rights reserved</div>
            </div>
        </>
    )
}