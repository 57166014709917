import React from "react"

import logo from "../Assets/logo.png"

export default function FooterMobile() {
    return (
        <>
            <div className="footerMain" style={{ padding: "10px 20px", background: "whitesmoke", height: "108px", fontSize: "5px" }}>
                <div className="d-flex">
                    <div style={{ width: "60%", fontSize: 7 }}>
                        <img height="20" src={logo} alt="logo" style={{ marginLeft: -5 }} />
                        <br /><br />
                        <div>Everyday is a new day for us and we work really hard</div>
                        <div>to satisfy our customers everywhere.</div>
                    </div>
                    <div style={{ width: "40%" }}>
                        <div className="d-flex">
                            <div className="text-center" style={{ width: "15%", marginTop: 1 }}><i className="fas fa-map-marker-alt"></i></div>
                            <div>
                                <a style={{ color: "black" }} href="https://goo.gl/maps/LTYRdSj4KoNMA9Vd6" target="_blank" rel="noreferrer">
                                    <div style={{ fontSize: 6 }}>Gading Orchard Summarecon</div>
                                    <div style={{ fontSize: 6 }}>Blok GOS No. B08, Jl. Terusan Kelapa</div>
                                    <div style={{ fontSize: 6 }}>Hybrida Summarecon, Kelapa Gading,</div>
                                    <div style={{ fontSize: 6 }}>Jakarta Utara, 14140</div>
                                </a>
                            </div>
                        </div>
                        <div className="d-flex" style={{ margin: "2px 0px" }}>
                            <div className="text-center" style={{ width: "15%", marginTop: 1 }}><i className="fas fa-mobile-alt"></i></div>
                            <div>
                                <div className="d-flex justify-content-between" style={{ fontSize: 6, width: 65 }}>
                                    <div>Tel:</div>
                                    <div>(021)290-766-76</div>
                                </div>
                                <div className="d-flex justify-content-between" style={{ fontSize: 6, width: 65 }}>
                                    <div>Fax:</div>
                                    <div>(021)290-766-75</div>
                                </div>
                                <div className="d-flex justify-content-between" style={{ fontSize: 6, width: 65 }}>
                                    <div>WA:</div>
                                    <div>0813-8853-5357</div>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex">
                            <div className="text-center" style={{ width: "15%", marginTop: 1 }}><i className="far fa-envelope"></i></div>
                            <div><a style={{ fontSize: 6, color: "inherit", textDecoration: "none" }} href="mailto:avicargoservice@gmail.com">avicargoservice@gmail.com</a></div>
                        </div>
                    </div>
                </div>
                <hr style={{ margin: "4px 0px 3px 0px", opacity: 1 }} />
                <div style={{fontSize: 7}}>Copyright © 2021 by Avi Cargo Services. All rights reserved</div>
            </div>
        </>
    )
}