import React from "react"
import { Link } from "react-router-dom"
import { Carousel } from "react-bootstrap"
import CountUp from "react-countup"

// import Home2 from './home2'

import road_freight from "../Assets/Icon/home2/road_freight.png"
import air_freight from "../Assets/Icon/home2/air_freight.png"
import sea_freight from "../Assets/Icon/home2/sea_freight.png"
import warehousing from "../Assets/Icon/home2/warehousing.png"

import projects_done from "../Assets/Icon/home3/projects_done.png"
import domestic_clients from "../Assets/Icon/home3/domestic_clients.png"
import owned_vehicles from "../Assets/Icon/home3/owned_vehicles.png"
import people_in_team from "../Assets/Icon/home3/people_in_team.png"

import danone from "../Assets/Customers/danone.png"
import cleo from "../Assets/Customers/cleo.png"
import sampoerna from "../Assets/Customers/sampoerna.png"
import matahari from "../Assets/Customers/matahari.png"
import wings from "../Assets/Customers/wings.png"
import indocement from "../Assets/Customers/indocement.png"
import kompas_gramedia from "../Assets/Customers/kompas_gramedia.png"
import hero from "../Assets/Customers/hero.png"
import indofood from "../Assets/Customers/indofood.png"

import logo from "../Assets/logo.png"

export default function Home() {
    const [currentImage, setCurrentImage] = React.useState(1)
    const [asd, setAsd] = React.useState(1)

    const directionButtons = (direction) => {
        return (
            <i style={{ fontSize: 50, color: "#dcc45f" }} className={direction === "next" ? "fas fa-caret-right" : "fas fa-caret-left"}></i>
        )
    }

    return (
        <div style={{ background: "black", width: "100vw" }}>
            <div className="home1">
                <div>
                    <h1>Avi Cargo Services</h1>
                    <h5>Your Trusted Transportation and Logistic Partner</h5>
                </div>
            </div>
            <div className="home2">
                <div className={`${currentImage === 1 ? "active3" : "undefined"} home2a`} onClick={() => setCurrentImage(1)}>
                    <div><img height="100" src={road_freight} alt="road_freight" /></div>
                    <div style={{ width: 104 }}>Road Freight</div>
                </div>
                <div className={`${currentImage === 2 ? "active3" : "undefined"} home2b`} onClick={() => setCurrentImage(2)}>
                    <div><img height="100" src={air_freight} alt="air_freight" /></div>
                    <div style={{ width: 86 }}>Air Freight</div>
                </div>
                <div className={`${currentImage === 3 ? "active3" : "undefined"} home2c`} onClick={() => setCurrentImage(3)}>
                    <div><img height="100" src={sea_freight} alt="sea_freight" /></div>
                    <div style={{ width: 92 }}>Sea Freight</div>
                </div>
                <div className={`${currentImage === 4 ? "active3" : "undefined"} home2d`} onClick={() => setCurrentImage(4)}>
                    <div><img height="100" src={warehousing} alt="warehousing" /></div>
                    <div>Warehousing</div>
                </div>
            </div>
            <div className="home3">
                <div>
                    <img height="50" src={projects_done} alt="projects_done" />
                    <br /><br />
                    <h3><CountUp start={0} end={1160} duration={5} />+</h3>
                    <div>Projects Done</div>
                </div>
                <div>
                    <img height="50" src={domestic_clients} alt="domestic_clients" />
                    <br /><br />
                    <h3><CountUp start={0} end={120} duration={5} />+</h3>
                    <div>Domestic Clients</div>
                </div>
                <div>
                    <img height="50" src={owned_vehicles} alt="owned_vehicles" />
                    <br /><br />
                    <h3><CountUp start={0} end={25} duration={5} />+</h3>
                    <div>Owned Vehicles</div>
                </div>
                <div>
                    <img height="50" src={people_in_team} alt="people_in_team" />
                    <br /><br />
                    <h3><CountUp start={0} end={25} duration={5} />+</h3>
                    <div>People in Team</div>
                </div>
            </div>
            <div className="home4">
                <br />
                <h1>Better Logistics</h1>
                <h1>Solution For You</h1>
                <br />
                <h5 style={{ width: 634 }}>To stay ahead of the competition, your business needs inventive</h5>
                <h5 style={{ width: 491 }}>solutions that solve for today’s fast-paced market</h5>
                <br /><br />
                <button>
                    <Link to="/services" style={{ color: "inherit" }}>
                        <span>EXPLORE MORE </span>
                        <i className="fas fa-long-arrow-alt-right"></i>
                    </Link>
                </button>
            </div>
            <div className="home5">
                <h1 style={{ width: 758 }}>Few Reasons Why You Should</h1>
                <h1 style={{ width: 699 }}>Choose Us. Protect Yourself</h1>
                <br /><br />
                <div>
                    <div onClick={() => setAsd(1)} style={{ cursor: asd === 2 && "pointer", width: 389 }}>
                        <div>
                            {asd === 1 && <i className="fas fa-check"></i>}
                            <span> Experienced Team</span>
                        </div>
                        <hr style={{ border: asd === 1 ? "1px solid black" : "1px solid transparent" }} />
                        <div style={{ color: asd === 1 ? "black" : "transparent" }}>
                            <div style={{ width: 300 }}>AVI possesses significant</div>
                            <div style={{ width: 300 }}>experience of all aspects of collective</div>
                            <div style={{ width: 300 }}>redress and group claims</div>
                        </div>
                    </div>
                    <div onClick={() => setAsd(2)} style={{ cursor: asd === 1 && "pointer", width: 389 }}>
                        <div style={{ width: 350 }}>
                            {asd === 2 && <i className="fas fa-check"></i>}
                            <span> Integrated Innovations</span>
                        </div>
                        <hr style={{ border: asd === 2 ? "1px solid black" : "1px solid transparent" }} />
                        <div style={{ color: asd === 2 ? "black" : "transparent" }}>
                            <div style={{ width: 350 }}>We provide improved technology with</div>
                            <div style={{ width: 300 }}>trusted quality and performance</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="home6">
                <img height="50" src={logo} alt="logo" />
                <br />
                <h1>A New Track for Your Business</h1>
                <br />
                <button>
                    <Link to="/contacts" style={{ color: "inherit" }}>CONNECT WITH US</Link>
                </button>
            </div>
            <div className="home7">
                <br />
                <div>
                    <h1>Special Services</h1>
                    <br />
                    <div>Nationally known for our ability to handle every last detail of our</div>
                    <div>customers’ particular logistics and forwarding needs, AVI Cargo’s</div>
                    <div>Special Services team takes care of all your logistics.</div>
                </div>
                <Carousel className="karousel" prevIcon={directionButtons("prev")} nextIcon={directionButtons("next")}>
                    <Carousel.Item>
                        <div style={{ marginTop: "5vw" }}>
                            <div>
                                <div className="Gotham-Med" style={{ marginTop: "6.65vw" }}>DOOR TO DOOR</div>
                                <br />
                                <div style={{ height: 80, fontSize: "0.97vw" }}>
                                    <div>Our delivery system is designed</div>
                                    <div>to carry out door to door</div>
                                    <div>delivery service on time,</div>
                                    <div>efficiently and effectively</div>
                                </div>
                            </div>
                            <div>
                                <div className="Gotham-Med" style={{ marginTop: "6.65vw" }}>FREIGHT FORWARDING</div>
                                <br />
                                <div style={{ height: 80, fontSize: "0.97vw" }}>
                                    <div>Our wide experience in this field</div>
                                    <div>of service ensures efficient</div>
                                    <div>handling of your cargos</div>
                                </div>
                            </div>
                            <div>
                                <div className="Gotham-Med" style={{ marginTop: "6.65vw" }}>CONTAINER DEPOT</div>
                                <br />
                                <div style={{ height: 80, fontSize: "0.97vw" }}>
                                    <div>We provide you depot</div>
                                    <div>or yard where several containers</div>
                                    <div>are stored or held in transit,</div>
                                    <div>once they are unloaded</div>
                                </div>
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div style={{ marginTop: "5vw" }}>
                            <div>
                                <div className="Gotham-Med" style={{ marginTop: "6.65vw" }}>WAREHOUSING</div>
                                <br />
                                <div style={{ height: 80, fontSize: "0.97vw" }}>
                                    <div>Public warehousing through a</div>
                                    <div>clear communication of your</div>
                                    <div>future needs, adequate resources</div>
                                    <div>can be put in place for you</div>
                                </div>
                            </div>
                            <div>
                                <div className="Gotham-Med" style={{ marginTop: "6.65vw" }}>TRUCKING</div>
                                <br />
                                <div style={{ height: 80, fontSize: "0.97vw" }}>
                                    <div>Our truck is supported by</div>
                                    <div>experienced and professional</div>
                                    <div>logistics team which offers full</div>
                                    <div>service solutions to meet all inland</div>
                                    <div>transportation & distribution</div>
                                </div>
                            </div>
                            <div>
                                <div className="Gotham-Med" style={{ marginTop: "6.65vw" }}>EXPORT / IMPORT</div>
                                <br />
                                <div style={{ height: 80, fontSize: "0.97vw" }}>
                                    <div>Our new services provide your</div>
                                    <div>international shipping simpler,</div>
                                    <div>easier, and safer</div>
                                </div>
                                {/* <br />
                                <div className="readMore">
                                    <Link to="/services" style={{ color: "inherit" }}>
                                        <span>read more</span>
                                        <span> </span>
                                        <i className="fas fa-long-arrow-alt-right"></i>
                                    </Link>
                                </div> */}
                            </div>
                        </div>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className="home8">
                <h3>Moving your products across all borders surely and safely</h3>
                <br />
                <div>We designed a detailed homepage layouts that will fit any transportation industry size.</div>
                <div>We will take care of your cargo or your pasenger and deliver them safe and on time!</div>
                <br />
                <button>
                    <Link to="/contacts" style={{ color: "inherit" }}>
                        <span>CONTACT NOW </span>
                        <i className="fas fa-long-arrow-alt-right"></i>
                    </Link>
                </button>
            </div>
            <div className="home9">
                <h1>Our Customers</h1>
                <br />
                <div>
                    <img height="40" src={danone} alt="danone" />
                    <img height="70" src={cleo} alt="cleo" />
                    <img height="110" src={sampoerna} alt="sampoerna" />
                    <img height="30" src={matahari} alt="matahari" />
                    <img height="50" src={wings} alt="wings" />
                    <img height="80" src={indocement} alt="indocement" />
                    <img height="90" src={kompas_gramedia} alt="kompas_gramedia" />
                    <img height="40" src={hero} alt="hero" />
                    <img height="30" src={indofood} alt="indofood" />
                </div>
            </div>
            <div className="home10"></div>
        </div>
    )
}