import React from "react"
import { Link } from "react-router-dom"

import danone from "../Assets/Customers/danone.png"
import cleo from "../Assets/Customers/cleo.png"
import sampoerna from "../Assets/Customers/sampoerna.png"
import matahari from "../Assets/Customers/matahari.png"
import wings from "../Assets/Customers/wings.png"
import indocement from "../Assets/Customers/indocement.png"
import kompas_gramedia from "../Assets/Customers/kompas_gramedia.png"
import hero from "../Assets/Customers/hero.png"
import indofood from "../Assets/Customers/indofood.png"

export default function Services() {
    return (
        <div style={{ width: "100vw" }}>
            <div className="services1">
                <h1>Services</h1>
                <div>
                    <div>
                        <Link to="/" style={{ color: "inherit" }}>Home</Link>
                    </div>
                    <div>/</div>
                    <div>Services</div>
                </div>
            </div>
            <div className="services2">
                <div>
                    <div>
                        <div className="Gotham-Med" style={{ marginTop: "6.65vw" }}>DOOR TO DOOR</div>
                        <br />
                        <div style={{ height: 80, fontSize: "0.97vw" }}>
                            <div>Our delivery system is designed</div>
                            <div>to carry out door to door</div>
                            <div>delivery service on time,</div>
                            <div>efficiently and effectively</div>
                        </div>
                    </div>
                    <div>
                        <div className="Gotham-Med" style={{ marginTop: "6.65vw" }}>FREIGHT FORWARDING</div>
                        <br />
                        <div style={{ height: 80, fontSize: "0.97vw" }}>
                            <div>Our wide experience in this field</div>
                            <div>of service ensures efficient</div>
                            <div>handling of your cargos</div>
                        </div>
                    </div>
                    <div>
                        <div className="Gotham-Med" style={{ marginTop: "6.65vw" }}>CONTAINER DEPOT</div>
                        <br />
                        <div style={{ height: 80, fontSize: "0.97vw" }}>
                            <div>We provide you depot</div>
                            <div>or yard where several containers</div>
                            <div>are stored or held in transit,</div>
                            <div>once they are unloaded</div>
                        </div>
                    </div>
                </div>
                <br /><br />
                <div>
                    <div>
                        <div className="Gotham-Med" style={{ marginTop: "6.65vw" }}>WAREHOUSING</div>
                        <br />
                        <div style={{ height: 80, fontSize: "0.97vw" }}>
                            <div>Public warehousing through a</div>
                            <div>clear communication of your</div>
                            <div>future needs, adequate resources</div>
                            <div>can be put in place for you</div>
                        </div>
                    </div>
                    <div>
                        <div className="Gotham-Med" style={{ marginTop: "6.65vw" }}>TRUCKING</div>
                        <br />
                        <div style={{ height: 80, fontSize: "0.97vw" }}>
                            <div>Our truck is supported by</div>
                            <div>experienced and professional</div>
                            <div>logistics team which offers full</div>
                            <div>service solutions to meet all inland</div>
                            <div>transportation & distribution</div>
                        </div>
                    </div>
                    <div>
                        <div className="Gotham-Med" style={{ marginTop: "6.65vw" }}>EXPORT / IMPORT</div>
                        <br />
                        <div style={{ height: 80, fontSize: "0.97vw" }}>
                            <div>Our new services provide your</div>
                            <div>international shipping simpler,</div>
                            <div>easier, and safer</div>
                        </div>
                        {/* <br />
                        <div className="readMore">
                            <span>read more</span>
                            <span> </span>
                            <i className="fas fa-long-arrow-alt-right"></i>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className="services3"></div>
            <div className="home9">
                <h1>Our Customers</h1>
                <br />
                <div>
                    <img height="40" src={danone} alt="danone" />
                    <img height="70" src={cleo} alt="cleo" />
                    <img height="110" src={sampoerna} alt="sampoerna" />
                    <img height="30" src={matahari} alt="matahari" />
                    <img height="50" src={wings} alt="wings" />
                    <img height="80" src={indocement} alt="indocement" />
                    <img height="90" src={kompas_gramedia} alt="kompas_gramedia" />
                    <img height="40" src={hero} alt="hero" />
                    <img height="30" src={indofood} alt="indofood" />
                </div>
            </div>
        </div>
    )
}