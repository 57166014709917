import React from "react"
import { Link } from "react-router-dom"

import danone from "../Assets/Customers/danone.png"
import cleo from "../Assets/Customers/cleo.png"
import sampoerna from "../Assets/Customers/sampoerna.png"
import matahari from "../Assets/Customers/matahari.png"
import wings from "../Assets/Customers/wings.png"
import indocement from "../Assets/Customers/indocement.png"
import kompas_gramedia from "../Assets/Customers/kompas_gramedia.png"
import hero from "../Assets/Customers/hero.png"
import indofood from "../Assets/Customers/indofood.png"

export default function ServicesMobile() {
    return (
        <div>
            <div style={{ height: 185, color: "white" }} className="services1 d-flex justify-content-evenly align-items-center">
                <h1 style={{ fontSize: 35 }}>Services</h1>
                <div className="d-flex justify-content-between" style={{ background: "", width: "30%", fontSize: 10 }}>
                    <div>
                        <Link to="/" style={{ color: "inherit" }}>Home</Link>
                    </div>
                    <div>/</div>
                    <div style={{ background: "", color: "#DCC45F", width: "45%" }}>Services</div>
                </div>
            </div>
            <div className="d-flex flex-wrap justify-content-center align-items-center" style={{ height: 631, background: "whitesmoke", padding: "15px 0px" }}>
                <div className="home7a" style={{ width: 160, height: 175, margin: "5px 10px", padding: "65px 20px" }}>
                    <div style={{ fontSize: 11 }} className="Gotham-Med">DOOR TO DOOR</div>
                    <div style={{ fontSize: 8, margin: "5px 0px" }}>Our delivery system is designed to carry out door to door delivery service on time, efficiently and effectively</div>
                </div>
                <div className="home7b" style={{ width: 160, height: 175, margin: "5px 10px", padding: "65px 20px" }}>
                    <div style={{ fontSize: 11 }} className="Gotham-Med">FREIGHT FORWARDING</div>
                    <div style={{ fontSize: 8, margin: "5px 0px" }}>Our wide experience in this field of service ensures efficient handling of your cargos</div>
                </div>
                <div className="home7c" style={{ width: 160, height: 175, margin: "5px 10px", padding: "65px 20px" }}>
                    <div style={{ fontSize: 11 }} className="Gotham-Med">CONTAINER DEPOT</div>
                    <div style={{ fontSize: 8, margin: "5px 0px" }}>We provide you depot or yard where several containers are stored or held in transit, once they are unloaded</div>
                </div>
                <div className="home7d" style={{ width: 160, height: 175, margin: "5px 10px", padding: "65px 20px" }}>
                    <div style={{ fontSize: 11 }} className="Gotham-Med">WAREHOUSING</div>
                    <div style={{ fontSize: 8, margin: "5px 0px" }}>Public warehousing through a clear communication of your future needs, adequate resources can be put in place for you</div>
                </div>
                <div className="home7e" style={{ width: 160, height: 175, margin: "5px 10px", padding: "65px 20px" }}>
                    <div style={{ fontSize: 11 }} className="Gotham-Med">TRUCKING</div>
                    <div style={{ fontSize: 8, margin: "5px 0px" }}>Our truck is supported by experienced and professional logistics team which offers full service solutions to meet all inland transportation & distribution</div>
                </div>
                <div className="home7f" style={{ width: 160, height: 175, margin: "5px 10px", padding: "65px 20px" }}>
                    <div style={{ fontSize: 11 }} className="Gotham-Med">EXPORT / IMPORT</div>
                    <div style={{ fontSize: 8, margin: "5px 0px" }}>Our new services provide your international shipping simpler, easier, and safer</div>
                </div>
            </div>
            <div style={{ height: 211 }} className="services2"></div>
            <div className="home9" style={{ height: 101, padding: "20px 25px" }}>
                <div className="Gotham-Med">Our Customers</div>
                <div className="d-flex justify-content-between align-items-center" style={{ marginTop: 5 }}>
                    <img height="10" src={danone} alt="danone" />
                    <img height="20" src={cleo} alt="cleo" />
                    <img height="30" src={sampoerna} alt="sampoerna" />
                    <img height="8" src={matahari} alt="matahari" />
                    <img height="15" src={wings} alt="wings" />
                    <img height="20" src={indocement} alt="indocement" />
                    <img height="25" src={kompas_gramedia} alt="kompas_gramedia" />
                    <img height="10" src={hero} alt="hero" />
                    <img height="10" src={indofood} alt="indofood" />
                </div>
            </div>
        </div>
    )
}