import React from "react"
import { Link } from "react-router-dom"
import { Carousel } from "react-bootstrap"
import CountUp from "react-countup"

import projects_done from "../Assets/Icon/home3/projects_done.png"
import domestic_clients from "../Assets/Icon/home3/domestic_clients.png"
import owned_vehicles from "../Assets/Icon/home3/owned_vehicles.png"
import people_in_team from "../Assets/Icon/home3/people_in_team.png"

import danone from "../Assets/Customers/danone.png"
import cleo from "../Assets/Customers/cleo.png"
import sampoerna from "../Assets/Customers/sampoerna.png"
import matahari from "../Assets/Customers/matahari.png"
import wings from "../Assets/Customers/wings.png"
import indocement from "../Assets/Customers/indocement.png"
import kompas_gramedia from "../Assets/Customers/kompas_gramedia.png"
import hero from "../Assets/Customers/hero.png"
import indofood from "../Assets/Customers/indofood.png"

import logo from "../Assets/logo.png"

export default function HomeMobile() {
    const directionButtons = (direction) => {
        return (
            <i style={{ fontSize: 25, color: "#dcc45f" }} className={direction === "next" ? "fas fa-caret-right" : "fas fa-caret-left"}></i>
        )
    }

    return (
        <div>
            <div className="home1" style={{ height: 688, width: "100vw" }}>
                <div style={{ color: "#E5E5E5", padding: "18vw 8vw" }}>
                    <h1 className="Gotham-Med" style={{ fontSize: 50 }}>Avi Cargo Services</h1>
                    <div style={{ width: "85%", fontSize: "4.8vw", marginTop: 15 }}>Your Trusted Transportation and Logistic Partner</div>
                </div>
            </div>
            <div className="home2 d-flex flex-wrap" style={{ height: 206, width: "100vw", padding: "25px" }}>
                <div className="d-flex justify-content-between align-items-center" style={{ height: "50%", width: "50%" }}>
                    <div className="text-center" style={{ width: "50%" }}>
                        <img height="50" src={projects_done} alt="projects_done" />
                    </div>
                    <div style={{ width: "50%" }}>
                        <div style={{ fontSize: 26 }}><CountUp start={0} end={1160} duration={5} />+</div>
                        <div style={{ fontSize: 9 }}>Projects Done</div>
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center" style={{ height: "50%", width: "50%" }}>
                    <div className="text-center" style={{ width: "50%" }}>
                        <img height="50" src={owned_vehicles} alt="owned_vehicles" />
                    </div>
                    <div style={{ width: "50%" }}>
                        <div style={{ fontSize: 26 }}><CountUp start={0} end={120} duration={5} />+</div>
                        <div style={{ fontSize: 9 }}>Owned Vehicles</div>
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center" style={{ height: "50%", width: "50%" }}>
                    <div className="text-center" style={{ width: "50%" }}>
                        <img height="50" src={domestic_clients} alt="domestic_clients" />
                    </div>
                    <div style={{ width: "50%" }}>
                        <div style={{ fontSize: 26 }}><CountUp start={0} end={25} duration={5} />+</div>
                        <div style={{ fontSize: 9 }}>Domestic Clients</div>
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center" style={{ height: "50%", width: "50%" }}>
                    <div className="text-center" style={{ width: "50%" }}>
                        <img height="50" src={people_in_team} alt="people_in_team" />
                    </div>
                    <div style={{ width: "50%" }}>
                        <div style={{ fontSize: 26 }}><CountUp start={0} end={25} duration={5} />+</div>
                        <div style={{ fontSize: 9 }}>People in Team</div>
                    </div>
                </div>
            </div>
            <div className="home4" style={{ height: 289, width: "100vw", padding: "60px 40px", color: "white" }}>
                <h1>Better Logistics Solution For You</h1>
                <div style={{ fontSize: 12 }}>To stay ahead of the competition, your business needs inventive solutions that solve for today’s fast-paced market</div>
                <br />
                <button style={{ height: "30px", width: "150px", background: "#DBBC41", color: "black", display: "flex", justifyContent: "space-evenly", alignItems: "center", border: "1px solid transparent", borderRadius: "30px" }}>
                    <Link to="/services" className="d-flex justify-content-evenly" style={{ color: "inherit", width: 150 }}>
                        <span className="Gotham-Med" style={{ fontSize: 9, marginTop: 1 }}>EXPLORE MORE </span>
                        <i style={{ fontSize: 14 }} className="fas fa-long-arrow-alt-right"></i>
                    </Link>
                </button>
            </div>
            <div className="home5" style={{ height: 427, width: "100vw", padding: "45px 30px" }}>
                <h1 style={{ width: 300 }} className="Gotham-Med">Few Reasons Why You Choose Us. Protect Yourself</h1>
                <div>
                    <br />
                    <div>
                        <div className="Gotham-Med">Experienced Team</div>
                        <div>AVI possesses significant experience of all aspects of collective redress and group claims</div>
                    </div>
                    <br />
                    <div>
                        <div className="Gotham-Med">Integrated Innovations</div>
                        <div>We provide improved technology with trusted quality and performance</div>
                    </div>
                </div>
            </div>
            <div className="home6 d-flex flex-column justify-content-center align-items-center" style={{ height: 234, width: "100vw" }}>
                <img height="35" src={logo} alt="logo" />
                <h6 style={{ margin: "20px 0px", fontSize: 20 }} className="Gotham-Med">A New Track for Your Business</h6>
                <button style={{ height: "30px", fontSize: 7, width: "100px", background: "black", color: "white", display: "flex", justifyContent: "space-evenly", alignItems: "center", border: "1px solid transparent", borderRadius: "30px" }}>
                    <Link to="/contacts" style={{ color: "inherit", marginTop: 2 }}>CONNECT WITH US</Link>
                </button>
            </div>
            <div className="home7" style={{ height: 250, width: "100vw", color: "white" }}>
                <div style={{ padding: "20px 20px 0px 20px" }}>
                    <div>Special Services</div>
                    <div style={{ fontSize: 8, color: "lightgrey", marginTop: "10px" }}>Nationally known for our ability to handle every last detail of our customers’ particular logistics and forwarding needs, AVI Cargo’s Special Services team takes care of all your logistics.</div>
                </div>
                <Carousel style={{ height: 150, padding: "0px 50px" }} prevIcon={directionButtons("prev")} nextIcon={directionButtons("next")}>
                    <Carousel.Item>
                        <div className="d-flex justify-content-between align-items-center" style={{ color: "black", background: "", height: 150 }}>
                            <div className="home7a" style={{ width: "32%", height: "70%", padding: "35px 15px" }}>
                                <div style={{ fontSize: 6 }} className="Gotham-Med">DOOR TO DOOR</div>
                                <div style={{ fontSize: 4, margin: "5px 0px" }}>Our delivery system is designed to carry out door to door delivery service on time, efficiently and effectively</div>
                            </div>
                            <div className="home7b" style={{ width: "32%", height: "70%", padding: "35px 15px" }}>
                                <div style={{ fontSize: 6 }} className="Gotham-Med">FREIGHT FORWARDING</div>
                                <div style={{ fontSize: 4, margin: "5px 0px" }}>Our wide experience in this field of service ensures efficient handling of your cargos</div>
                            </div>
                            <div className="home7c" style={{ width: "32%", height: "70%", padding: "35px 15px" }}>
                                <div style={{ fontSize: 6 }} className="Gotham-Med">CONTAINER DEPOT</div>
                                <div style={{ fontSize: 4, margin: "5px 0px" }}>We provide you depot or yard where several containers are stored or held in transit, once they are unloaded</div>
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className="d-flex justify-content-between align-items-center" style={{ color: "black", background: "", height: 150 }}>
                            <div className="home7d" style={{ width: "32%", height: "70%", padding: "35px 15px" }}>
                                <div style={{ fontSize: 6 }} className="Gotham-Med">WAREHOUSING</div>
                                <div style={{ fontSize: 4, margin: "5px 0px" }}>Public warehousing through a clear communication of your future needs, adequate resources can be put in place for you</div>
                            </div>
                            <div className="home7e" style={{ width: "32%", height: "70%", padding: "35px 15px" }}>
                                <div style={{ fontSize: 6 }} className="Gotham-Med">TRUCKING</div>
                                <div style={{ fontSize: 4, margin: "5px 0px" }}>Our truck is supported by experienced and professional logistics team which offers full service solutions to meet all inland transportation & distribution</div>
                            </div>
                            <div className="home7f" style={{ width: "32%", height: "70%", padding: "35px 15px" }}>
                                <div style={{ fontSize: 6 }} className="Gotham-Med">EXPORT / IMPORT</div>
                                <div style={{ fontSize: 4, margin: "5px 0px" }}>Our new services provide your international shipping simpler, easier, and safer</div>
                            </div>
                        </div>
                    </Carousel.Item>
                </Carousel>
            </div>
            <div className="home8" style={{ height: 108, width: "100vw", color: "white", padding: "20px 55px 0px 25px" }}>
                <div style={{ fontSize: 9 }} className="Gotham-Med">Moving your products across all borders surely and safely</div>
                <div style={{ fontSize: 8, margin: "5px 0px", color: "lightgrey" }}>We designed a detailed homepage layouts that will fit any transportation industry size. We will take care of your cargo or your pasenger and deliver them safe and on time!</div>
                <button style={{ height: "15px", width: "100px", background: "#DBBC41", color: "black", display: "flex", alignItems: "center", border: "1px solid transparent", borderRadius: "30px" }}>
                    <Link to="/contacts" className="d-flex justify-content-evenly" style={{ color: "inherit", width: 100 }}>
                        <span style={{ fontSize: 5, marginTop: 1 }} className="Gotham-Med">CONTACT NOW </span>
                        <i style={{ fontSize: 8 }} className="fas fa-long-arrow-alt-right"></i>
                    </Link>
                </button>
            </div>
            <div className="home9" style={{ height: 101, width: "100vw", padding: "20px 25px" }}>
                <div className="Gotham-Med">Our Customers</div>
                <div className="d-flex justify-content-between align-items-center" style={{ marginTop: 5 }}>
                    <img height="10" src={danone} alt="danone" />
                    <img height="20" src={cleo} alt="cleo" />
                    <img height="30" src={sampoerna} alt="sampoerna" />
                    <img height="8" src={matahari} alt="matahari" />
                    <img height="15" src={wings} alt="wings" />
                    <img height="20" src={indocement} alt="indocement" />
                    <img height="25" src={kompas_gramedia} alt="kompas_gramedia" />
                    <img height="10" src={hero} alt="hero" />
                    <img height="10" src={indofood} alt="indofood" />
                </div>
            </div>
            <div className="home10" style={{ height: 182, width: "100vw" }}></div>
        </div>
    )
}