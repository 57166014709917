import React from "react"
import { Container } from "react-bootstrap"
import { Routes, Route } from "react-router-dom"
import { BrowserView, MobileView } from "react-device-detect"
import "./App.css"

import ScrollToTop from "./Components/ScrollToTop"
import Navigation from "./Components/navigation"
import Footer from "./Components/footer"
import Home from "./Pages/home"
import Services from "./Pages/services"
import AboutUs from "./Pages/about_us"
import Contacts from "./Pages/contacts"

import NavigationMobile from "./PagesMobile/0navigationMobile"
import HomeMobile from "./PagesMobile/1home"
import ServicesMobile from "./PagesMobile/2services"
import AboutUsMobile from "./PagesMobile/3about_us"
import ContactsMobile from "./PagesMobile/4contacts"
import FooterMobile from "./PagesMobile/5footerMobile"

function App() {
  return (
    <>
      <BrowserView className="hide">
        <Navigation />
        <Container style={{ padding: 0, margin: 0 }}>
          <ScrollToTop />
          <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/services" element={<Services />} />
              <Route path="/about-us" element={<AboutUs />} />
              <Route path="/contacts" element={<Contacts />} />
          </Routes>
        </Container>
        <Footer />
      </BrowserView>
      <MobileView className="hideMobile">
        <NavigationMobile />
        <Container style={{ padding: 0, margin: 0 }}>
          <ScrollToTop />
          <Routes>
              <Route exact path="/" element={<HomeMobile />} />
              <Route path="/services" element={<ServicesMobile />} />
              <Route path="/about-us" element={<AboutUsMobile />} />
              <Route path="/contacts" element={<ContactsMobile />} />
          </Routes>
        </Container>
        <FooterMobile />
      </MobileView>
    </>
  )
}

export default App
