import React from "react"
import { Link } from "react-router-dom"
import emailjs from "emailjs-com"

import danone from "../Assets/Customers/danone.png"
import cleo from "../Assets/Customers/cleo.png"
import sampoerna from "../Assets/Customers/sampoerna.png"
import matahari from "../Assets/Customers/matahari.png"
import wings from "../Assets/Customers/wings.png"
import indocement from "../Assets/Customers/indocement.png"
import kompas_gramedia from "../Assets/Customers/kompas_gramedia.png"
import hero from "../Assets/Customers/hero.png"
import indofood from "../Assets/Customers/indofood.png"

export default function Contacts() {
    // const [checked, setChecked] = React.useState(false)

    const form = React.useRef()
    const submitForm = (e) => {
        e.preventDefault()
        emailjs.sendForm("service_ei1vxtv", "template_2qxf5vd", form.current, "QoiiFNSvmYQiJFmkE")
            .then((res) => {
                console.log(res)
                document.getElementById("theForm").reset()
            }, (err) => console.log(err))
    }

    return (
        <div style={{ width: "100vw" }}>
            <div className="contacts1">
                <h1>Contacts</h1>
                <div>
                    <div>
                        <Link to="/" style={{ color: "inherit" }}>Home</Link>
                    </div>
                    <div>/</div>
                    <div>Contacts</div>
                </div>
            </div>
            <div className="contacts2">
                <div>
                    <h1>Contact Us</h1>
                    <br />
                    <div className="contacts2input">
                        <div>
                            <div>For inquiries and further information</div>
                            <div>about AVI Cargo Services or how we</div>
                            <div>can support your business, please</div>
                            <div>contact us directly through the</div>
                            <div>categories below</div>
                        </div>
                        <form ref={form} onSubmit={submitForm} id="theForm" className="d-flex">
                            <div className="disableTextSelect">
                                <input required placeholder="Name*" name="from_name" />
                                <br /><br />
                                <input required placeholder="Email*" name="from_email" type="email" />
                                <br /><br />
                                {/* <div className="d-flex align-items-center" onClick={() => setChecked(!checked)} style={{ cursor: "pointer" }}>
                                    <input style={{ cursor: "pointer", margin: "0px 10px" }} checked={checked} onChange={e => {}} type="checkbox" />
                                    <span>Subscribe to our newsletter</span>
                                </div> */}
                            </div>
                            <div className="disableTextSelect">
                                <textarea required placeholder="Message*" name="message" />
                                <br /><br />
                                <button type="submit">
                                    <span className="Gotham-Med">SUBMIT </span>
                                    <i className="fas fa-long-arrow-alt-right"></i>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="home10"></div>
            <div className="home9">
                <h1>Our Customers</h1>
                <br />
                <div>
                    <img height="40" src={danone} alt="danone" />
                    <img height="70" src={cleo} alt="cleo" />
                    <img height="110" src={sampoerna} alt="sampoerna" />
                    <img height="30" src={matahari} alt="matahari" />
                    <img height="50" src={wings} alt="wings" />
                    <img height="80" src={indocement} alt="indocement" />
                    <img height="90" src={kompas_gramedia} alt="kompas_gramedia" />
                    <img height="40" src={hero} alt="hero" />
                    <img height="30" src={indofood} alt="indofood" />
                </div>
            </div>
        </div>
    )
}