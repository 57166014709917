import React from "react"
import { Container, Navbar, Nav } from "react-bootstrap"
import { Link } from "react-router-dom"

import logo from "../Assets/logo.png"

export default function Navigation() {
    return (
        <>
            <Navbar collapseOnSelect expand="xl">
                <Container>
                    <Navbar.Brand style={{ background: "", marginRight: 0 }}>
                        <img height="30" src={logo} alt="logo" />
                    </Navbar.Brand>
                    <div className="d-flex" style={{ fontSize: 6, background: "", marginTop: 2, marginLeft: -2 }}>
                        <div className="d-flex flex-column justify-content-between" style={{ height: 23 }}>
                            <div><i style={{ width: 11 }} className="text-center fas fa-mobile-alt"></i><span>CALL: (021) 290-766-76</span></div>
                            <div><i style={{ width: 11 }} className="text-center far fa-clock"></i><span>Mon - Sat: 9AM - 5PM</span></div>
                        </div>
                        <div className="d-flex flex-column justify-content-between" style={{ height: 23, marginLeft: 10 }}>
                            <div><a style={{ color: "black" }} href="mailto:avicargoservice@gmail.com"><i style={{ width: 11 }} className="text-center far fa-envelope"></i><span>avicargoservice@gmail.com</span></a></div>
                            <div><a style={{ color: "black" }} href="https://goo.gl/maps/LTYRdSj4KoNMA9Vd6" target="_blank" rel="noreferrer"><i style={{ width: 11 }} className="text-center fas fa-globe-americas"></i><span>Jakarta Office</span></a></div>
                        </div>
                    </div>
                    <Navbar.Toggle className="shadow-none" style={{ fontSize: 12 }} />
                    <Navbar.Collapse>
                        <Nav>
                            <Nav.Link style={{ borderBottom: "1px solid lightgrey" }} as={Link} to="/" href='/'>Home</Nav.Link>
                            <Nav.Link style={{ borderBottom: "1px solid lightgrey" }} as={Link} to="/services" href='/services'>Services</Nav.Link>
                            <Nav.Link style={{ borderBottom: "1px solid lightgrey" }} as={Link} to="/about-us" href='/about-us'>About Us</Nav.Link>
                            <Nav.Link style={{ borderBottom: "1px solid lightgrey" }} as={Link} to="/contacts" href='/contacts'>Contacts</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <div className="whatsapp">
                <a href="https://api.whatsapp.com/send?phone=6281388535357&text=Halo,%20saya%20ingin%20bertanya%20mengenai%20Avi%20Cargo%20Services" target="_blank" rel="noreferrer">
                    <i className="fab fa-whatsapp"></i>
                </a>
            </div>
        </>
    )
}