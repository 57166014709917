import React from "react"
import { Link } from "react-router-dom"

import projects_done from "../Assets/Icon/home3/projects_done.png"
import domestic_clients from "../Assets/Icon/home3/domestic_clients.png"
import owned_vehicles from "../Assets/Icon/home3/owned_vehicles.png"
import people_in_team from "../Assets/Icon/home3/people_in_team.png"

import danone from "../Assets/Customers/danone.png"
import cleo from "../Assets/Customers/cleo.png"
import sampoerna from "../Assets/Customers/sampoerna.png"
import matahari from "../Assets/Customers/matahari.png"
import wings from "../Assets/Customers/wings.png"
import indocement from "../Assets/Customers/indocement.png"
import kompas_gramedia from "../Assets/Customers/kompas_gramedia.png"
import hero from "../Assets/Customers/hero.png"
import indofood from "../Assets/Customers/indofood.png"

export default function AboutUsMobile() {
    return (
        <div>
            <div style={{ height: 185, color: "white" }} className="services1 d-flex justify-content-evenly align-items-center">
                <h1 style={{ fontSize: 35 }}>About Us</h1>
                <div className="d-flex justify-content-between" style={{ background: "", width: "30%", fontSize: 10 }}>
                    <div>
                        <Link to="/" style={{ color: "inherit" }}>Home</Link>
                    </div>
                    <div>/</div>
                    <div style={{ background: "", color: "#DCC45F", width: "45%" }}>About Us</div>
                </div>
            </div>
            <div className="about-us2" style={{ height: 640 }}></div>
            <div className="home2 d-flex flex-wrap" style={{ height: 206, padding: "25px" }}>
                <div className="d-flex justify-content-between align-items-center" style={{ height: "50%", width: "50%" }}>
                    <div className="text-center" style={{ width: "50%" }}>
                        <img height="50" src={projects_done} alt="projects_done" />
                    </div>
                    <div style={{ background: "transparent", width: "50%" }}>
                        <div style={{ fontSize: 26 }}>1160+</div>
                        <div style={{ fontSize: 9 }}>Projects Done</div>
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center" style={{ height: "50%", width: "50%" }}>
                    <div className="text-center" style={{ width: "50%" }}>
                        <img height="50" src={owned_vehicles} alt="owned_vehicles" />
                    </div>
                    <div style={{ background: "transparent", width: "50%" }}>
                        <div style={{ fontSize: 26 }}>25+</div>
                        <div style={{ fontSize: 9 }}>Owned Vehicles</div>
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center" style={{ height: "50%", width: "50%" }}>
                    <div className="text-center" style={{ width: "50%" }}>
                        <img height="50" src={domestic_clients} alt="domestic_clients" />
                    </div>
                    <div style={{ background: "transparent", width: "50%" }}>
                        <div style={{ fontSize: 26 }}>120+</div>
                        <div style={{ fontSize: 9 }}>Domestic Clients</div>
                    </div>
                </div>
                <div className="d-flex justify-content-between align-items-center" style={{ height: "50%", width: "50%" }}>
                    <div className="text-center" style={{ width: "50%" }}>
                        <img height="50" src={people_in_team} alt="people_in_team" />
                    </div>
                    <div style={{ background: "transparent", width: "50%" }}>
                        <div style={{ fontSize: 26 }}>25+</div>
                        <div style={{ fontSize: 9 }}>People in Team</div>
                    </div>
                </div>
            </div>
            <div className="home9" style={{ height: 101, padding: "20px 25px" }}>
                <div className="Gotham-Med">Our Customers</div>
                <div className="d-flex justify-content-between align-items-center" style={{ marginTop: 5 }}>
                    <img height="10" src={danone} alt="danone" />
                    <img height="20" src={cleo} alt="cleo" />
                    <img height="30" src={sampoerna} alt="sampoerna" />
                    <img height="8" src={matahari} alt="matahari" />
                    <img height="15" src={wings} alt="wings" />
                    <img height="20" src={indocement} alt="indocement" />
                    <img height="25" src={kompas_gramedia} alt="kompas_gramedia" />
                    <img height="10" src={hero} alt="hero" />
                    <img height="10" src={indofood} alt="indofood" />
                </div>
            </div>
        </div>
    )
}