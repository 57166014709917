import React from "react"
import { useLocation } from "react-router-dom"
import { Navbar, Nav } from "react-bootstrap"
import { Link } from "react-router-dom"

import logo from "../Assets/logo.png"

export default function Navigation() {
    const history = useLocation()
    const currentUrl = (url) => history.pathname === url

    return (
        <>
            <Navbar className="navbar1">
                <img height="41" src={logo} alt="logo" />
                <div><i className="fas fa-mobile-alt"></i><span style={{ fontSize: "1vw" }}>CALL: (021) 290-766-76</span></div>
                <div>
                    <i className="far fa-envelope"></i>
                    <span><a href="mailto:avicargoservice@gmail.com" style={{ fontSize: "1vw" }}>avicargoservice@gmail.com</a></span>
                </div>
                <div><i className="far fa-clock"></i><span style={{ fontSize: "1vw" }}>Mon - Sat: 9AM - 5PM</span></div>
                <a href="https://goo.gl/maps/LTYRdSj4KoNMA9Vd6" target="_blank" rel="noreferrer">
                    <i className="fas fa-globe-americas"></i><span style={{ fontSize: "1vw" }}>Jakarta Office</span>
                </a>
            </Navbar>
            <Navbar className="navbar2">
                <Nav>
                    <Nav.Link as={Link} to="/" className={currentUrl("/") && "active2"}><div>Home</div></Nav.Link>
                    <Nav.Link as={Link} to="/services" className={currentUrl("/services") && "active2"}><div>Services</div></Nav.Link>
                    <Nav.Link as={Link} to="/about-us" className={currentUrl("/about-us") && "active2"}><div>About Us</div></Nav.Link>
                    <Nav.Link as={Link} to="/contacts" className={currentUrl("/contacts") && "active2"}><div>Contacts</div></Nav.Link>
                </Nav>
            </Navbar>
            <div className="whatsapp">
                <a href="https://api.whatsapp.com/send?phone=6281388535357&text=Halo,%20saya%20ingin%20bertanya%20mengenai%20Avi%20Cargo%20Services" target="_blank" rel="noreferrer">
                    <i className="fab fa-whatsapp"></i>
                </a>
            </div>
        </>
    )
}